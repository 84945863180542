/* Particles */
.blinx__particles {
    position: absolute;
    /* top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
    pointer-events: none;
    z-index: 101;
    width: 100%;
    height: 100%;
}

.blinx__particles-container {
    height: 100%;
    width: 100%;
}

.-item {
    position: absolute;
    will-change: transform;
    transition: all .3s linear ease-in;
}

.-item:hover {
    transform: scale(1.08);
}

.-dot {
    animation: animate 25s linear infinite;
    animation-duration: calc(100s / var(--i));
    animation-delay: 2s;
    animation-fill-mode: forwards;
    background-color: #1AA6B7;
    border-radius: 50%;
    height: 12px;
    opacity: 0;
    width: 12px;
}

.-dot:nth-child(even){
    background-color: #FFD2D7;
}
.-dot:nth-child(3n){
    background-color: #ffb200;
}


.-logo img {
    height: 36px;
    width: 36px;
}

.-card img {
    height: 40px;
    width: 40px;
}

.-item img {
    animation: animate 25s linear infinite;
    animation-duration: calc(200s / var(--i));
}


.-v5 { left: 12%; }
.-v6 { left: 17%; }
.-v7 { left: 12%; }
.-v8 { left: 14%; }
.-v9 { right: 5%; }
.-v10 { right: 8%; }
.-v11 { right: 12%; }
.-v12 { right: 14%; }

.-v1 { left: 17%;}
.-v2 { left: 10%;}
.-v3 { left: 80%;}
.-v4 { left: 65%;}


@keyframes animate {
    0% {
        transform: translateY(calc(100vh + 480px));
        opacity: 0;
    }
    5% {
        opacity: 1;
    }

    100% {
        transform: translateY(0vh);
    }
}

@media screen and (min-width: 1024px) {
    .-dot {
        height: 20px;
        width: 20px;
    }

    .-logo img {
        height: 64px;
        width: 64px;
    }

    .-card img {
        height: 80px;
        width:80px;
    } 
}