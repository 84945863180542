.blinx__features {
    background-color: #F8FEFF;
    padding: 80px 0 80px;
    position: relative;
    z-index: 99;
}

.blinx__features-container {
    padding: 0 25px 0;
}


.blinx__features-header {
    margin-bottom: 50px;
    text-align: center;
}

.blinx__features-header h3 {
    font-family: inherit;
    font-weight: 400;
    font-size: 70px;
    line-height: 80px;
}


@media screen and (min-width: 1024px) {
    .blinx__features-container {
        padding: 0 40px 0;
    }
}

@media screen and (min-width: 1512px) {
    .blinx__features-container {
        padding: 0 75px 0;
    }
}


@media screen and (max-width: 768px) {
    .blinx__features-header h3 {
        font-family: inherit;
        font-weight: 700;
        font-size: 35px;
        line-height: 40px;
    }
}