.blinx__layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    /* overflow: auto; */
}

.blinx__layout-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
}

.blinx__layout-body {
    background-color: var(--bg-light);
    z-index: 1000;
}

.blinx__layout-footer {
    position: sticky;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}