.blinx__testimonials {
    background-color: #F8FEFF;
    margin-bottom: -62px;
}
.blinx__testimonials-container {
    padding: 80px 25px 150px;
}

.blinx__testimonials-header {
    margin-bottom: 40px;
}

.blinx__testimonials-header h1 {
    font-family: inherit;
    font-weight: 700;
    font-size: 35px;
    line-height: 35px;
    text-align: center;
}



@media screen and (min-width: 768px) {}
@media screen and (min-width: 1024px) {
    .blinx__testimonials {
        margin-bottom: -209px;
    }

    .blinx__testimonials-container {
        padding: 80px 25px 289px;
    }

    .blinx__testimonials-header {
        margin-bottom: 60px;
    }

    .blinx__testimonials-header_text {
        margin: auto;
        width: 60%;
    }

    .blinx__testimonials-header h1 {
        font-size: 70px;
        line-height: 80px;
    }
}
@media screen and (min-width: 1512px) {}