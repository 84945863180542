.blinx__feature-row {
    border-radius: 40px;
    margin-bottom: 72px;
    padding: 0 40px;
    overflow: hidden;
}

.blinx__feature-row:last-child {
    margin-bottom: 0;
}

.blinx__feature-row .blinx__feature-col.-left {
    flex: .5;
}

.blinx__feature-row .blinx__feature-col.-right {
    flex: .4;
}

.blinx__feature-row h3.-feature_title {
    font-weight: 500;
    font-size: 35px;
    line-height: 40px;
    letter-spacing: 0em;
    margin-bottom: 24px;
}

.blinx__feature-row p.-feature_subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
}

.blinx__feature-row .-feature_img-container img {
    height: 100%;
    width: 100%;
    margin-bottom: -10px;
}

@media screen and (min-width: 1024px) {
    .blinx__feature-row h3.-feature_title {
        font-size: 54px;
    }

    .blinx__feature-row p.-feature_subtitle {
        font-size: 22px;
    }
}

@media screen and (min-width: 1512px) {
    .blinx__feature-row h3.-feature_title {
        font-size: 70px;
        line-height: 105px;
    }

    .blinx__feature-row p.-feature_subtitle {
        font-size: 25px;
        line-height: 48px;
    }

    .blinx__feature-row {
        padding: 0 75px;
    }
}

@media screen and (max-width: 824px) {
    .blinx__feature-row {
        border-radius: 40px;
        flex-direction: column;
        padding: 80px 20px 0;
    }
    
    .blinx__feature-row .-feature_img-container img  {
        object-fit: cover;
        height: auto;
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    
}