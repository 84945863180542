
.gap-0 {
    gap: .5rem;
}
.gap-1 {
    gap: 1rem;
}
.gap-2 {
    gap: 1.5rem;
}
.gap-3 {
    gap: 2rem;
}
.gap-4 {
    gap: 2.5rem;
}

.w-100 {
    width: 100%;
}
.h-100 {
    width: 100%;
}



.d-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}

.flex-1 {
    flex-grow: 1;
}

.flex-column {
    flex-direction: column;
}

.item-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.justify-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.justify-left {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: left;
    -webkit-justify-content: left;
    -moz-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
}
.justify-right {
    -webkit-box-pack: right;
    -webkit-justify-content: right;
    -moz-box-pack: right;
    -ms-flex-pack: right;
    justify-content: right;
}
.justify-between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}