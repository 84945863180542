.blinx__step {
    text-align: center;
}

.blinx__step img {
    height: auto;
    width: 100%;
}

.blinx__step p {
    color: var(--color-dark);
    font-family: inherit;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    margin-top: 30px;
}

@media screen and (max-width: 768px) {
    .blinx__step {
        max-width: 340px;
        margin: auto;
    }
}

@media screen and (max-width: 390px) {
    .blinx__step {
        max-width: 100%;
    }
}