.blinx__oppurtunity {
    background-color: #021416;
    color: #fff;
    padding: 4rem 0;
    position: relative;
    z-index: 99;
}

.blinx__oppurtunity-container {
    padding: 0 25px;
}

.blinx__oppurtunity-header {
    margin-bottom: 4rem;
    position: relative;
}

.blinx__oppurtunity-header > div.-top {
    margin-left: -190px;
}

.blinx__oppurtunity-header > div.-bottom {
    margin-right: -190px;
}

.blinx__oppurtunity-header h2 {
    font-size: 56px;
    font-weight: 400;
    line-height: 64px;
}

.blinx__oppurtunity-header p {
    font-size: 24px;
    font-weight: 400;
}

.blinx__oppurtunity-row {
    border-top: 1px solid #002D40;
    padding: 2rem 0;
}

.blinx__oppurtunity-row p {
    font-size: 25px;
    font-weight: 400;
    line-height: 39px;
}

.blinx__oppurtunity-col {
    flex: 1;
    text-align: center;
}


@media screen and (min-width: 768px) {
    .blinx__oppurtunity-container {
        padding: 0 40px;
    }
}

@media screen and (min-width: 1024px) {
    .blinx__oppurtunity-container {
        padding: 0 60px;
    }

    .blinx__oppurtunity-header h2 {
        font-size: 72px;
        line-height: 90px;
    }

    .blinx__oppurtunity-header > div.-top {
        margin-left: -230px;
    }
    
    .blinx__oppurtunity-header > div.-bottom {
        margin-right: -230px;
    }
}

@media screen and (min-width: 1512px) {
    .blinx__oppurtunity {
        padding: 4rem 0;
    }

    .blinx__oppurtunity-container {
        padding: 0 105px;
    }

    .blinx__oppurtunity-header {
        margin-bottom: 8rem;
    }

    .blinx__oppurtunity-header h2 {
        font-size: 120px;
        line-height: 128px;
    }

    .blinx__oppurtunity-header p {
        font-size: 44px;
        line-height: 52.8px;
    }
}


@media screen and (max-width: 768px) {
    .blinx__oppurtunity-row.d-flex {
        border-top: 0;
        flex-direction: column;
        gap: 0;
        padding: 0;
    }

    .blinx__oppurtunity-col {    
        border-top: 1px solid #002D40;
        flex: 1;
        padding: 2rem 0;
        width: 100%;
    }
    

    .blinx__oppurtunity-header > div.-top {
        margin-left: 0;
    }
    
    .blinx__oppurtunity-header > div.-bottom {
        margin-right: 0;
    }
}

@media screen and (max-width: 500px) {
    .blinx__oppurtunity h2 {
        font-size: 44px;
        line-height: 52px;
    }
}