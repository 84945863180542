.blinx__footer {
    background-color:var(--bg-dark);
    color: var(--bg-white);
    padding: 140px 0 50px;
}

.blinx__footer-container {
    padding:0 25px;
}

.blinx__footer-text {
    max-width: 286px;
    margin: 30px 0 0;
}

.blinx__footer-logo {
    height: 64px;
    width: 64px;
    overflow: hidden;
}

.blinx__footer-logo img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.blinx__footer-text h3 {
    font-family: inherit;
    font-weight: 700;
    font-size: 35px;
    line-height: 35px;
}

.blinx__footer-navs span {
    font-family: inherit;
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
}

.blinx__footer-link span {
    font-weight: 400;
    color: #8B98B1;
    font-size: 18px;
    line-height: 27px;
}

.blinx__footer-link.blinx__footer-mail span {
    color: #fff;
    text-decoration: underline;
}

.blinx__footer-top .blinx__footer-row .blinx__footer-col {
    flex: 1;
}

.blinx__footer-top .blinx__footer-row .blinx__footer-col.-right {
    padding: 47px 0 0 0;
}

.blinx__footer-top .blinx__footer-navs a {
    display: block;
    padding: 13px 0;
}

.blinx__footer-bottom {
    margin: 115px 0 0 0;
}


/* Max widths */
@media screen and (max-width: 1024px) {
    .blinx__footer-top .blinx__footer-row {
        flex-direction: column;
    }
}

@media screen and (max-width: 767px) {
    .blinx__footer-bottom .blinx__footer-row {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 390px) {
    .blinx__footer-bottom .blinx__footer-col-right {
        justify-content: space-between;
    }
}

@media screen and (max-width: 289px) {
    .blinx__footer-text {
        max-width: 100%;
    }
}


/* Min widths */
@media screen and (min-width: 768px) {
    .blinx__footer-container {
        margin: auto;
    }

    .blinx__footer-bottom .blinx__footer-row {
        flex-direction: row;
        justify-content: space-between;
    }

    .blinx__footer-bottom .blinx__footer-row .blinx__footer-col-left {
        flex-direction: row;
    }
}
@media screen and (min-width: 1024px) {
    .blinx__footer {
        padding: 328px 0 118px;
    }

    .blinx__footer-container {
        padding: 40px;
    }

    .blinx__footer-logo {
        height: 80px;
        width: 80px;
    }

    .blinx__footer-text h3 {
        font-weight: 700;
        font-size: 44px;
        line-height: 64px;
    }

    .blinx__footer-top .blinx__footer-row .blinx__footer-col.-right {
        padding: 100px 0 0 0;
    }
}

@media screen and (min-width: 1512px) {
    .blinx__footer-container {
        max-width: 1512px;
        padding: 0 75px;
    }
}
