@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --bg-light: #F8FEFF;
  --bg-white: #ffffff;
  --color-primary: #1AA6B7;
  --color-primary-light: #E7FCFF;
  --bg-dark:  #021416;
  --color-black: #191919;
  --color-dark:  #021416;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

html.has-scroll-smooth {
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;  
}

html, body {
  font-style: normal;
  font-size: 100%;
  scroll-behavior: smooth;
  line-height: 1.5;
}

body {
  cursor: pointer;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;

  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.service.active h2{
  opacity: 1 !important;
}

/* [data-scrollbar] {
  height: 100vh;
  overflow: hidden;
  background-color: var(--gray-1);
}
[data-scrollbar] .scroll-content {
  background-color: var(--dark-bg);
}
[data-scrollbar] .scrollbar-track.scrollbar-track-y {
  z-index: 101;
  background: var(--deep-dark);
}
[data-scrollbar] .scrollbar-track.scrollbar-track-y .scrollbar-thumb-y {
  background: var(--gray-1);
}

.scrollbar-track{
  background-color: transparent !important;
}

.scrollbar-track-y{
  width:0.75rem !important;
} */


.scrollbar-thumb{
  width:0.625rem !important;
  border-radius: 0.4rem;
  background-color: #1AA6B7 !important;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(196, 194, 194);
}