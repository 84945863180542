.blinx__faq {
    margin-bottom: 80px;
    width: 100%;
}

.blinx__faq-hero {
    background-color: var(--color-primary-light);
    background-image: url('../../assets/bg-light.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: left;
    border-radius: 0px 0px 60px 60px;
    margin-bottom: 80px;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    text-align: center;
    position: relative;
    width: 100%;
}
.blinx__faq-hero_container {
    padding: 0 25px;
}
.blinx__faq-hero_container img {
    width: 40px;
    height: 40px;
}

.blinx__faq-hero_text  {
    text-align: center;
}

.blinx__faq-hero_text div {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.blinx__faq-hero_text h1 {
    font-family: inherit;
    font-size: 52px;
    font-weight: 700;
    line-height: 90%;
}

.blinx__faq-content_fields {
    margin: auto;
    max-width: 90%;
}

.blinx__faq-btns {
    background-color: var(--color-primary);
}

.scroller {
    border-radius: 50%;
    position: absolute;
    bottom: 40px;
    left: 24px;
    height: 74px;
    width: 74px;
}

.scroller button {
    border: none;
    border-radius: 50%;
    background-color: var(--color-primary);
    height: 100%;
    outline: none;
    width: 100%;
}

@media screen and (min-width: 768px) {
    .blinx__faq-hero_text h1 {
        font-size: 72px;
        line-height: 110%;
    }
    .blinx__faq-hero_container img {
        height: 64px;
        width: 64px;
    }
}
@media screen and (min-width: 1024px) {
    .blinx__faq-hero {
        background-size: contain;
        border-radius: 0px 0px 150px 150px;
    }

    .blinx__faq-hero_container img {
        height: 80px;
        width: 80px;
    }

    .blinx__faq-hero_text h1 {
        font-size: 6.6666666667vw;
    }

    .scroller {
        display: block;
        bottom: 80px;
        left: 75px;
    }
}
@media screen and (min-width: 1512px) {}