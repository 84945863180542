
.blinx__accordion {
    border: 1px solid #E2E8F0;
    border-radius: 24px;
    margin-bottom: 25px;
    padding: 25px 40px;
    transition: all 300ms ease-in-out;
}

.blinx__accordion:last-child {
    margin-bottom: 0;
}

.blinx__accordion-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.blinx__accordion.-active .blinx__accordion-header svg {
    rotate: 45deg;
    transition: all .25s ease-in-out;
}

.blinx__accordion-header h3 {
    color: var(--color-black);
    font-family: inherit;
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
    margin-left: 20px;
}

.blinx__accordion-header svg {
    font-size: 1.5rem;
}

.blinx__accordion-body {
    margin-top: 15px;
    margin-left: 40px;
    overflow: hidden;
    padding: 0 40px 0 0;
}

.blinx__accordion-body.active {
    display: block;
    height: max-content;
    width: 100%;
    transition: all 300ms ease-in-out;
}

.blinx__accordion-body p {
    color: var(--color-black);
    font-family: inherit;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}



@media screen and (min-width: 768px) {
    .blinx__accordion-header h3 {
        margin-left: 50px;
    }
    .blinx__accordion-body {
        margin-left: 70px;
    }
}
@media screen and (min-width: 1024px) {
    .blinx__accordion {
        border-radius: 50px;
        padding: 63px 100px;
    }
}

@media screen and (max-width: 600px) {
    .blinx__accordion-header h3 {
        font-size: 18px;
        line-height: 31px;
    }

    .blinx__accordion-body p {
        font-size: 14px;
        line-height: 22px ;
    }
}


.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }