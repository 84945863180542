.blinx__testimonial {
    align-items: center;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
}

.blinx__testimonial-imgbox {
    margin-bottom: 42px;
    position: relative;
    width: max-content;
}

.blinx__testimonial-imgbox img {

}

.blinx__testimonial-iconbox {
    position: absolute;
    bottom: -23.5px;
    right: 0;
}

.blinx__testimonial-iconbox img {
    height: 47px;
    width: 47px;
}

.blinx__testimonial-textbox {
    background-color: #E2E8F0;
    border-radius: 20px;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 101px;
    padding: 35px 40px;
}

.blinx__testimonial-text_description {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: center;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
}
.blinx__testimonial-text_details {
    margin-bottom: 25px;
    text-align: center;
}

.blinx__testimonial p {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
}

.blinx__testimonial h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
}

.blinx__slider-btns {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: center;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
}

.blinx__slider-btns button {
    background-color: var(--bg-white);
    border-radius: 50%;
    border: none;
    height: 59px;
    outline: none;
    width: 59px;
}


@media screen and (min-width: 768px) {
    .blinx__testimonial-text_description {
        flex-direction: row;
        -webkit-justify-content: space-between;
        justify-content: space-between;
    }

    .blinx__testimonial-text_details {
        margin-bottom: 0;
        text-align: left;
    }
}
@media screen and (min-width: 1024px) {
    .blinx__testimonial {
        flex-direction: row;
        gap: 40px;
        margin: auto;
        width: 80%;
    }

    .blinx__testimonial-imgbox {
        margin-bottom: 0;
        width: 30%;
    }

    .blinx__testimonial-imgbox img {
        width: 100%;
        height: auto;
    }

    .blinx__testimonial-textbox {
        width: 67%;
    }
}
@media screen and (min-width: 1512px) {
    .blinx__testimonial {
        width: 60%;
    }
}