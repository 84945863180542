.blinx__cta {
    background-color: #F8FEFF;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    margin-bottom: -62px;
    position: relative;
    z-index: 99;
}

.blinx__cta-container {
    padding: 125px 25px 80px;
}

.blinx__cta h3 {
    font-family: inherit;
    font-weight: 400;
    font-size: 56px;
    line-height: 64px;
}

.blinx__cta-text {
    text-align: center;
}

.blinx__cta-logo img {
    height: 64px;
    width: 64px;
}

/* waitlis */
.blinx__cta-waitlist {
    margin: 40px 0 120px;
    text-align: center;
}

.blinx__cta-waitlist button.blinx__cta-btn {
    background-color: var(--color-primary);
    border: none;
    border-radius: 100px;
    cursor: pointer;
    height: 87px;
    outline: none;
    width: 329px;
}

.blinx__cta-waitlist button.blinx__cta-btn span,
.blinx__cta-waitlist_form button span,
.blinx__cta-waitlist_form input {
    color: var(--bg-white);
    font-family: inherit;
    font-weight: 500;
    font-size: 25px;
    letter-spacing: 0.02em;
    line-height: 38px;
}

.blinx__cta-waitlist button.blinx__cta-btn:disabled {
    cursor: not-allowed;
}

.blinx__cta-waitlist_form form {
    background: var(--bg-white);
    background: #E7FCFF;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 87px;
    margin: auto;
    padding: 5px;
}

.blinx__cta-waitlist_form input {
    background-color: transparent;
    border: none;
    color: var(--color-dark);
    outline: none;
    padding: 35px 0 35px 50px;
    width: 100%;
}

.blinx__cta-waitlist_form button {
    background-color: var(--color-primary);
    border: none;
    border-radius: 100px;
    height: 80px;
    outline: none;
    padding:0 35px ;
}



.blinx__cta-app_img img {
    width: 100%;
    height: auto;
}

.scale_up_hor_center {
	-webkit-animation: scale-up-hor-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-hor-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-hor-center {
    0% {
      -webkit-transform: scaleX(0.4);
              transform: scaleX(0.4);
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
    }
  }
  @keyframes scale-up-hor-center {
    0% {
      -webkit-transform: scaleX(0.4);
              transform: scaleX(0.4);
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
    }
  }
  

@media screen and (min-width: 768px) {
    .blinx__cta-text {
        max-width: 60%;
        margin: auto;
    }
}

@media screen and (min-width: 1024px) {    
    .blinx__cta {
        border-bottom-left-radius: 150px;
        border-bottom-right-radius: 150px;
        margin-bottom: -209px;
    }

    .blinx__cta-container {
        padding: 120px 0 60px;
    }

    .blinx__cta-logo img {
        height: 80px;
        width: 80px;
    }

    .blinx__cta-app_img {
        margin: auto;
        max-width: 80%;
    }

    .blinx__cta h3 {
        font-size: 84px;
        line-height: 120px;
    }

    .blinx__cta-waitlist {
        margin-top: 60px;
    }

    .blinx__cta-waitlist button.blinx__cta-btn {
        height: 123px;
        width: 467px;
    }

    .blinx__cta-waitlist button.blinx__cta-btn span,
    .blinx__cta-waitlist_form button span, 
    .blinx__cta-waitlist_form input {
        font-size: 35px;
        line-height: 52px;
    }

    .blinx__cta-waitlist_form form {
        height: 123px;
        width: 914px;
        overflow: hidden;
    }
    .blinx__cta-waitlist_form input {
        width: 100%;
    }

    .blinx__cta-waitlist_form button {
        height: 113px;
        width: 251px;
    }
}
@media screen and (min-width: 1512px) {}

@media screen and (max-width: 768px) {
    .blinx__cta {
        background-size: cover;
    }
}

@media screen and (max-width: 500px) {
    .blinx__cta h3 {
        font-size: 44px;
        line-height: 52px;
    }

    .blinx__cta-logo img {
        width: 52px;
        height: 52px;
    }
}