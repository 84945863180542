.blinx__steps {
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.99) 100%);
    padding: 80px 0 80px;
}

.blinx__steps-container {
    padding: 0 25px 0;
}

.blinx__steps-container_header {
    margin-bottom: 50px;
    text-align: center;
}

.blinx__steps-container_header h3 {
    font-family: inherit;
    font-weight: 700;
    font-size: 70px;
    line-height: 80px;
}

.blinx__steps-container_header h3.blinx__steps-text_light {
    color: var(--color-primary);
}

.blinx__steps-container_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 33px;
}

@media screen and (min-width: 768px) {
    .blinx__steps-container_grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 57px;
    }
}

@media screen and (min-width: 1024px) {
    .blinx__steps {
        padding: 200px 0 80px;
    }

    .blinx__steps-container {
        padding: 0 40px 0;
    }

    .blinx__steps-container_header {
        margin-bottom: 100px;
    }
}

@media screen and (min-width: 1512px) {
    .blinx__steps-container {
        padding: 0 75px 0;
    }
}

@media screen and (max-width: 767px) {
    .blinx__steps {
        margin-bottom: 48px;
    }

    .blinx__steps-container_header h3 {
        font-family: inherit;
        font-weight: 700;
        font-size: 35px;
        line-height: 40px;
    }
}