.blinx__hero-cursor {
    background-color: #1AA6B7;
    border-radius: 50%;
    height: 25px;
    margin: -10px 0 0 -10px;
    pointer-events: none;
    position: fixed;
    user-select: none;
    width: 25px;
    will-change: transform;
    z-index: 1100;
}
.blinx__hero-cursor.-active {
    background-color: #fff;
}


@media screen and (max-width: 560px) {
    .blinx__hero-cursor {
        display: none;
    }
}
