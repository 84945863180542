.blinx__hero {
    cursor: none;
    position: relative;
}

.blinx__hero-content {
    padding: 0 0 60px;
    position: relative;
}

.blinx__hero-container {
    padding: 0 25px;
    width: 100%;
}

.blinx__hero-head {
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: fill-available;
    height: 100vh;
    padding: 12rem 0;
}

.blinx__hero-head .blinx__hero-container {
    padding: 0;
    position: relative;
    contain: layout style;
}

.blinx__hero-head .blinx__hero-head_box{
    text-align: center;
    mix-blend-mode: screen;
    min-height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
}

.blinx__hero-head_text {
    margin: auto;
    width: 85%;
    text-align: center;
}

.blinx__hero-head_text h1 {
    color: var(--color-primary);
    font-size: 52px;
    font-weight: 700; 
    line-height: 106%;
    position: relative;
    text-align: center;
    text-rendering: optimizeSpeed;
    z-index: 2;
}

/* SHAPES */
.blinx__hero-shapes {
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.blinx__hero-shape {
    will-change: transform;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    will-change: transform;
    border-radius: 50%;
}

.-shape1 {
    background-color: #1AA6B7;
    background-color: hsla(186, 75%, 41%, 0.08);
    height: 650px;
    margin: -325px 0 0 -325px;
    width: 650px;
}
.-shape2 {
    background-color: #7628ff;
    background-color: #005ffe;
    background-color: hsla(218, 100%, 50%, 0.08);
    height: 440px;
    margin: -220px 0 0 -220px;
    width: 440px;
}

.-shape3 {
    background-color: #ffb200;
    background: teal;
    background-color: hsla(42, 100%, 50%, 0.08);
    height: 272px;
    margin: -135px 0 0 -135px;
    width: 272px;
}

/* BODY */

.blinx__hero-body {
    margin-top: -125px;
    position: relative;
    z-index: 10;
}

.blinx__hero-img {
    height: max-content;
    margin: 0 auto;
    /* width: 265px; */
}
.blinx__hero-img img {
    height: 100%;
    width: auto;
    /* width: 100%; */
}

.blinx__hero-head {
    position: relative;
    z-index: 10;
} 
.blinx__hero-body, 
.blinx__hero-footer {
    position: relative;
    z-index: 101;
}



@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: translate3d(0px, 100%, 0px);
    }
    
    100% {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0px, 0px, 0px);
    }
}



@media screen and (min-width: 768px) {
    .blinx__hero-container {
        padding: 0 40px;
    }
    .blinx__hero-head_text h1 {
        font-size: 72px;
    }

    .blinx__hero-services {
        width: 427px;
    }

    .blinx__hero-service h2 {
        font-size: 54px;
    }
}
@media screen and (min-width: 1024px) {
    .blinx__hero-head_text h1 {
        font-size: 6.66667vw;
    }

    .blinx__hero-img {
        /* width: 338px; */
    }

}

@media screen and (min-width: 1512px) {}

@media screen and (max-width: 500px) {
    .blinx__hero-img {
        width: 95%;
    }


    .blinx__hero-img img {
        width: 100%;
        height: auto;
    }
}