.blinx__builtwith {
    background-color: #F8FAFF;
}

.blinx__builtwith-container {
    padding: 0 25px;
}

.blinx__builtwith-row {
    gap: 4rem;
}

.blinx__builtwith-col.-left {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 360px;
}

.blinx__builtwith-col.-left .blinx__builtwith-tools.-left {
    margin-top: -80px;
    position: relative;
}
.blinx__builtwith-col.-left .blinx__builtwith-tools.-right {
    margin-bottom: -80px;
    position: relative;
}

.blinx__builtwith-tool {
    background-color: var(--bg-white);
    border-radius: 192px;
    box-shadow: 0px 35px 64px 0px #00000014;
    height: 224px;
    width: 160px;
}

.blinx__builtwith-tool span {
    text-align: center;
}

.blinx__builtwith-col.-right {
    padding: 2rem 0;
}

.blinx__builtwith .-col_heading {
    margin-bottom: 3rem;
    position: relative;
}

.-col_heading span.-top {
    display: none;
}

.blinx__builtwith h2 {
    font-size: 56px;
    font-weight: 400;
    line-height: 64px;
}

.blinx__builtwith p {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    max-width: 540px;
}

.blinx__builtwith .-col_heading img {
    height: auto;
    min-width: 120px;
}


@media screen and (min-width: 768px) {
    .blinx__builtwith-container {
        padding: 0 40px;
    }
}

@media screen and (min-width: 1024px) {
    .blinx__builtwith-container {
        padding: 0 60px;
    }

    .blinx__builtwith h2 {
        font-size: 56px;
        line-height: 70px;
    }

    .blinx__builtwith .-col_heading img {
        width: 140px;
    }
}

@media screen and (min-width: 1512px) {
    .blinx__builtwith-container {
        padding: 0 75px;
    }

    .blinx__builtwith h2 {
        font-size: 72px;
        line-height: 88px;
    }

    .blinx__builtwith .-col_heading img {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .blinx__builtwith-row.d-flex {
        flex-direction: column;
        gap: 0;
    }

    .blinx__builtwith-col.-left {
        margin-top: -80px;
        rotate: 90deg;
    }

    .blinx__builtwith-col.-right {
        margin-top: -80px;
        width: 100%;
    }

    .blinx__builtwith-col.-left .blinx__builtwith-tool span,
    .blinx__builtwith-col.-left .blinx__builtwith-tool img {
        rotate: 270deg;
    }
}

@media screen and (max-width: 824px) {
    .blinx__builtwith-container {
        padding: 40px 25px;
    }

    .blinx__builtwith-row.d-flex {
        flex-direction: column;
        gap: 5rem;
    }

    .blinx__builtwith-col.-left .blinx__builtwith-tools.-left {
        margin-top: 0;
    }
    .blinx__builtwith-col.-left .blinx__builtwith-tools.-right {
        margin-top: 80px;
        position: relative;
    }

    .blinx__builtwith-col.-left {
        height: fit-content;
        width: 360px;
        rotate: 0deg;
    }

    .blinx__builtwith-col.-left,
    .blinx__builtwith-col.-right {
        margin: 0;
    }

    .blinx__builtwith-col.-left .blinx__builtwith-tool span,
    .blinx__builtwith-col.-left .blinx__builtwith-tool img {
        rotate: 0deg;
    }

    .blinx__builtwith p {
        width: 100%;
    }

    .-col_heading span.-top {
        display: inline-block;
    }
    .-col_heading span.-bottom {
        display: none;
    }

    .-col_heading img {
        height: auto;
        width: 96px;
    }
}

@media screen and (max-width: 500px) {
    .blinx__builtwith h2 {
        font-size: 44px;
        line-height: 52px;
    }

    .blinx__builtwith .-col_heading img {
        min-width: 64px;
    }

    .blinx__builtwith-tool {
        width: 135px;
        height: 200px;
    }
}