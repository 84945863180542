.blinx__navbar {
    width: 100%;
    position: relative;
    transition: all 0.3s;
    top: 0;
    z-index: 1009;
}

.blinx__navbar-content {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    z-index: 102;
}

.blinx__navbar-container {
    padding: 0 25px;
}


.logo {
    width: 103px;
    height: 25px;
}

.blinx__navbar-btn {
    background: #1AA6B7;
    border: none;
    border-radius: 133.33px;
    color: #F8FEFF;
    cursor: pointer;
    flex-shrink: 1;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4rem;
    outline: none;
    padding: 1rem;
    transition: all .25s ease-in-out;
    white-space: nowrap;
}

.blinx__navbar-btn:hover {
    background-color: white;
    box-shadow: 0px 35px 64px 0px #00000014;
    color: #1AA6B7;
}

.logo {
    cursor: pointer;
}

.blinx__navbar-toggle_menu {
    position: relative;
    top: 2.5px;
    display: inline-block;
    height: auto;
    margin: 0;
    padding: 0;
    color: inherit;
    cursor: pointer;
    background: 0 0;
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    outline: 0!important;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    text-align: inherit;
    text-transform: inherit;
    text-indent: inherit;
    width: 24px;
}

.blinx__navbar-toggle_menu:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
}

.blinx__navbar-toggle_menu span {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    margin: 8px 0;
    top: 0;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    background: var(--color-dark);
    -webkit-transition: top ease-out .15s .15s,-webkit-transform ease-out .15s 0s;
    transition: top ease-out .15s .15s,-webkit-transform ease-out .15s 0s;
    -o-transition: top ease-out .15s .15s,-o-transform ease-out .15s 0s;
    -moz-transition: top ease-out .15s .15s,transform ease-out .15s 0s,-moz-transform ease-out .15s 0s;
    transition: top ease-out .15s .15s,transform ease-out .15s 0s;
    transition: top ease-out .15s .15s,transform ease-out .15s 0s,-webkit-transform ease-out .15s 0s,-moz-transform ease-out .15s 0s,-o-transform ease-out .15s 0s;
}

.blinx__navbar.-open .blinx__navbar-toggle_menu span {
    background-color: var(--bg-white);
}

.blinx__navbar.-open .blinx__navbar-toggle_menu span:first-child {
    top: 6px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.blinx__navbar.-open .blinx__navbar-toggle_menu span:last-child {
    top: -4px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.blinx__navbar-fill {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 102;
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform: scaleY(0);
   -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center;
    background: var(--color-primary);
    pointer-events: none;
    -webkit-transition: -webkit-transform 1s cubic-bezier(.16,1,.3,1);
    transition: -webkit-transform 1s cubic-bezier(.16,1,.3,1);
    -o-transition: -o-transform 1s cubic-bezier(.16,1,.3,1);
    -moz-transition: transform 1s cubic-bezier(.16,1,.3,1),-moz-transform 1s cubic-bezier(.16,1,.3,1);
    transition: transform 1s cubic-bezier(.16,1,.3,1);
    transition: transform 1s cubic-bezier(.16,1,.3,1),-webkit-transform 1s cubic-bezier(.16,1,.3,1),-moz-transform 1s cubic-bezier(.16,1,.3,1),-o-transform 1s cubic-bezier(.16,1,.3,1);  
}

.blinx__navbar.-open .blinx__navbar-fill {
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
    pointer-events: auto;
}

.blinx__navbar-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 102;
    height: 65px;
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    -o-transition: -o-transform .4s;
    -moz-transition: transform .4s,-moz-transform .4s;
    transition: transform .4s;
    transition: transform .4s,-webkit-transform .4s,-moz-transform .4s,-o-transform .4s;
}

.blinx__navbar-bg.-active {
    background: rgba(248, 250, 255, 0.8);
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
}



@media screen and (min-width: 768px) {
    .blinx__navbar-btn {
        font-size: 16px;
        padding: 16px 24px;
    }
}

@media screen and (min-width: 1024px) {
    .blinx__navbar-fill {
        display: none;
    }

    .blinx__navbar-bg {
        height: 126px;
    }

    .blinx__navbar-content {
        top: 30px;
    }

    .logo {
        width: 183px;
        height: 44.3px;
    }

    .blinx__navbar-container_colLeft, 
    .blinx__navbar-container_colRight {
        flex: .5;
    }

    .blinx__navbar-container_colCenter {
        flex: 1;
        text-align: center;
    }

    .blinx__navbar-container_colRight {
        text-align: right;
    }

    .blinx__navbar-nav {
        display: flex;
        background-color: var(--bg-white);
        border-radius: 50px;
        gap: 30px;
        margin: auto;
        padding: 15px 40px;
        width: max-content;
    }

    .blinx__navbar-navItem span {
        font-size: 18px;
        font-weight: 500;
        font-family: inherit;
    }

    .blinx__navbar-navItem.-active span {
        color: var(--color-primary);
    }

    .blinx__navbar-navItem span:hover {
        color: var(--color-primary);
    }

    .blinx__navbar-toggle {
        display: none;
    }
}

@media screen and (min-width: 1512px) {
    .blinx__navbar-container {
        padding: 0 75px;
    }

    .blinx__navbar-nav {
        gap: 50px;
    }
}

@media screen and (max-width: 1024px) {
    .blinx__navbar.-open .blinx__navbar-fill {
        -webkit-transform: scaleY(1);
        -moz-transform: scaleY(1);
        -ms-transform: scaleY(1);
        -o-transform: scaleY(1);
        transform: scaleY(1);
        pointer-events: auto;
    }

    .blinx__navbar.-open .blinx__navbar-nav {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        -webkit-transition-delay: 0s,0s;
        -moz-transition-delay: 0s,0s;
        -o-transition-delay: 0s,0s;
        transition-delay: 0s,0s;
        -webkit-transition-duration: 0s,0s;
        -moz-transition-duration: 0s,0s;
        -o-transition-duration: 0s,0s;
        transition-duration: 0s,0s;
    }

    .blinx__navbar-nav {
        position: fixed;
        top: 0;
        left: 20px;
        right: 0;
        bottom: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: left;
        color: #fff;
        opacity: 1;
        visibility: hidden;
        pointer-events: none;
        -webkit-transition: opacity .3s 0s,visibility 0s .6s;
        -o-transition: opacity .3s 0s,visibility 0s .6s;
        -moz-transition: opacity .3s 0s,visibility 0s .6s;
        transition: opacity .3s 0s,visibility 0s .6s;
    }

    .blinx__navbar-logo {
        position: fixed;
        top: 20px;
        left: 25px;
        z-index: 1000;
    }

    .blinx__navbar-btn {
        position: relative;
        z-index: 100;
        background-color:  #F8FEFF;
        color: var(--color-primary);
    }

    .blinx__navbar-navItem {
        display: block;
        overflow: hidden;
        padding: 1em 0;
        text-decoration: none;
    }

    .blinx__navbar-navItem span {
        color: var(--bg-white);
        font-weight: 500;
        font-size: 35px;
        line-height: 35px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        -webkit-transition: opacity .1s linear,visibility 0s .1s;
        -o-transition: opacity .1s linear,visibility 0s .1s;
        -moz-transition: opacity .1s linear,visibility 0s .1s;
        transition: opacity .1s linear,visibility 0s .1s;
    
    }

    .blinx__navbar.-open .blinx__navbar-navItem span {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(0) translateZ(0);
        -moz-transform: translateY(0) translateZ(0);
        transform: translateY(0) translateZ(0);
    }

    .blinx__navbar.-open .blinx__navbar-navItem:nth-child(1) span {
        -webkit-transition-delay: .25s;
        -moz-transition-delay: .25s;
        -o-transition-delay: .25s;
        transition-delay: .25s;
    }

    .blinx__navbar.-open .blinx__navbar-navItem:nth-child(2) span {
        -webkit-transition-delay: .3s;
        -moz-transition-delay: .3s;
        -o-transition-delay: .3s;
        transition-delay: .3s;
    }
    .blinx__navbar.-open .blinx__navbar-navItem:nth-child(3) span {
        -webkit-transition-delay: .35s;
        -moz-transition-delay: .35s;
        -o-transition-delay: .35s;
        transition-delay: .35s;
    }

    .blinx__navbar-toggle {
        display: block;
        position: fixed;
        top: 20px;
        right: 25px;
        z-index: 1000;
        pointer-events: auto;
        -webkit-transition: -webkit-transform .4s;
        transition: -webkit-transform .4s;
        -o-transition: -o-transform .4s;
        -moz-transition: transform .4s,-moz-transform .4s;
        transition: transform .4s;
        transition: transform .4s,-webkit-transform .4s,-moz-transform .4s,-o-transform .4s;
    }    

    .blinx__navbar-actions {
        position: fixed;
        left: 50%;
        bottom: 50px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        transform: translateX(-50%);
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: row-reverse;
        -moz-box-orient: horizontal;
        -moz-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        -webkit-transition: opacity .1s linear,visibility 0s .1s;
        -o-transition: opacity .1s linear,visibility 0s .1s;
        -moz-transition: opacity .1s linear,visibility 0s .1s;
        transition: opacity .1s linear,visibility 0s .1s;
    }

    .blinx__navbar.-open .blinx__navbar-actions {
        opacity: 1;
        visibility: visible;
        z-index: 1000;
        pointer-events: initial;
    }
}


@media screen and (min-width: 1024px) {
    .blinx__navbar-container {
        padding: 0 40px;
    }
}

@media screen and (min-width: 1512px) {
    .blinx__navbar-container {
        padding: 0 75px;
    }
}

@media screen and (max-width: 767px) {
    .blinx__navbar-navItem span {
        font-size: 40px;
        line-height: 60px;
    }
}